"use client";
import { GRAPHICS, GraphicNames } from "./constants";
import { twMerge } from "tailwind-merge";
import useTheme from "@/shared/hooks/useTheme";
import { EndemicImage } from "../Image";

interface Props {
  graphicName: GraphicNames;
  className?: string;
  width?: number;
  height?: number;
  alt?: string;
}

const Graphics = ({ graphicName, className, width, height, alt }: Props) => {
  const theme = useTheme();
  const Icon = GRAPHICS[graphicName][theme];

  return (
    <div
      className={twMerge(
        "relative",
        className,
        width && `w-[${width}px]`,
        height && `h-[${height}px]`,
      )}
    >
      <EndemicImage
        src={Icon}
        width={0}
        height={0}
        className={"w-full h-auto"}
        alt={alt || `${graphicName}-icon`}
        aspectRatio
      />
    </div>
  );
};

export default Graphics;
